<template>
    <div>
        <b-sidebar aria-labelledby="sidebar-no-header-title" no-header id="clientst_ids" backdrop style="direction:ltr" width="50rem" right :title="$parent.lang.statment" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.statment}}</span>
                    </div>
                    <div @click="hide" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm closethis">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                
                <v-row :style="`direction:`+$parent.lang.dir+`;margin:5px;`">
                <v-col
                cols="12"
                md="12"
                sm="12">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{$parent.lang.start_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>{{$parent.lang.end_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label></label>
                            <b-input-group style="margin-top:-5px">
                            <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getReport()">{{$parent.lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                <!-- <b-input-group class="mt-3">
                    <b-form-input placeholder="Start Date" class="inborder" style="" type="date" v-model="sdate"></b-form-input>
                    <b-form-input placeholder="End Date" class="inborder" style="" type="date" v-model="edate"></b-form-input>
                    <b-input-group-append style="border:1px solid #000">
                        <b-button variant="light" style="background: #000 !important;font-size:14px;color:#fff;font-size:14px;padding:8px 22px;border-radius:5px" @click="getReport()">{{$parent.lang.search}}</b-button>
                    </b-input-group-append>
                </b-input-group> -->
                </v-col>
                <v-col cols="12" md="4" sm="12" class="text-right">
                    <b-button variant="light" style="width:145px;margin-right:9px;background:#000 !important;color:#FFF;display:none" class="mytab mytab1 mt-3" @click="exportE('e')">{{$parent.lang.export_excel}}</b-button>
                    <b-button variant="light" style="width:145px;margin-right:3px;background:#000;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
                </v-col>
            </v-row>
            <v-row :style="`direction:`+$parent.lang.dir+`;margin:5px;`">
                <v-col cols="12" md="12" sm="12" :style="`direction:`+$parent.lang.dir">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th colspan="5" class="text-center backBlack">{{$parent.lang.account_statement}} - {{account_name}}</th>
                            </tr>
                            <tr>
                                <th class="text-center ">{{$parent.lang.date}}</th>
                                <th class="text-center ">{{$parent.lang.description}}</th>
                                <th class="text-center ">{{$parent.lang.income}}</th>
                                <th class="text-center ">{{$parent.lang.payments}}</th>
                                <!-- <th class="text-center backBlack">{{$parent.lang.balance}}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in accountrows" :key="index">
                                <td class="text-center" style="direction:ltr">{{item.date}}</td>
                                <td class="text-center" style="direction:ltr">{{item.notes}}</td>
                                <td class="text-center" style="direction:ltr">{{item.depit}}</td>
                                <td class="text-center" style="direction:ltr">{{item.credit}}</td>
                                <!-- <td class="text-center" style="direction:ltr">{{item.balance}}</td> -->
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="4" class="backBlack"> {{$parent.lang.balance}} : {{btotals}} </th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
            </v-row>

            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            accountn: {
                full_name: '',
            },
 
            accountrows: [],
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDay() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    computed: {
        account_name: function() {
            if(this.$parent.lang.langname=='ar')
            {
                return this.accountn.full_name
            }else{
                return this.accountn.full_name;
            }
        },
        btotals: function()
        {
            let t = 0;
            let tc = 0;
            let td = 0;
            for(let i=0;i<this.accountrows.length;i++){
                tc = parseFloat(tc) + parseFloat(this.accountrows[i].credit)
                td = parseFloat(td) + parseFloat(this.accountrows[i].depit)
            }
            t = parseFloat(td) - parseFloat(tc);
            return this.$RoundNum(t);
        },
        sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.$parent.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.$parent.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.$parent.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
    },
    methods: {
        getReport(){
            const post = new FormData();
            post.append('type',"getStatReport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.accountn.customerid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                //console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data;
                }
            })
        }
    },
}
</script>